@media (min-width: 768px) {
  .canvasContainer {
    height: 374px;
  }
}

@media (min-width: 1280px) {
  .canvasContainer {
    height: 336px;
  }

  canvas {
    border-radius: 50%;
    box-shadow: 6px 10.4px 18px rgba(1, 1, 0, 0.1);
  }
}
