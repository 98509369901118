.logout_btn {
  display: flex;
  background: transparent;
  border: none;
}

.logoutSvg {
  display: flex;
  fill: #b5b5b6;
  width: 16px;
  height: 16px;
}

.logoutSvg:hover {
  cursor: pointer;
}
.logoutP {
  display: none;
  font-family: 'HelveticaNeueCyr';
  color: rgb(107, 125, 131);
  font-size: 15px;
}

@media (min-width: 768px) {
  .logoutSvg {
    display: block;
    margin-right: 10px;
  }
  .logoutP {
    display: flex;
    font-size: 15px;
    color: rgb(107, 125, 131);
  }
  .logoutP:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
