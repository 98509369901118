.link {
  display: block;
  width: 30px;
  height: 25px;
  margin: 0 14px;
  background-color: var(--bg-color-dark-blue);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.activeLink {
  background-color: #66778e;
}

.text {
  display: none;
}

@media (min-width: 768px) {
  .linkBox {
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }

  .link {
    width: 140px;
    background: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-decoration: none;
    color: #7e8e94;
    text-align: center;
    margin: 0;
  }

  .svg {
    margin: 0 10px 0 0;
  }

  .text::after {
    content: '';
    border-right: 2px solid var(--bg-color-dark-blue);
    height: 100%;
    margin-left: 15px;
  }

  .text {
    display: block;
    font-family: 'HelveticaNeueCyr', serif;
    font-size: 15px;
    letter-spacing: 1.3px;
    padding-bottom: 2px;
    margin-right: 16px;
  }

  .activeLink {
    color: var(--color-main-blue);
    font-weight: bold;
  }
}

@media (min-width: 1280px) {
  .link {
    color: #ffffff;
    width: 100%;
    margin: 0;
    padding: 19px 0 19px 26px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 0;
  }

  .activeLink {
    background-color: #66778e;
  }

  .text::after {
    display: none;
  }

  .svg {
    margin-right: 16px;
  }
}
