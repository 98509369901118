.container {
  min-width: 320px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 24px;
  height: 24px;
}

.title {
  color: #282828;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 1.44px;
  font-family: 'BauhausC';
}

.input {
  min-width: 275px;
  min-height: 48px;
  background-color: #f9fbfb;
  border: none;
  border-radius: 5px;
}

.input::-webkit-input-placeholder {
  font-family: 'HelveticaNeueCyr';
  color: #b9c9d4;
}

.inputWithIcon .input {
  padding-left: 58px;
}

.inputWithIcon {
  position: relative;
  margin-bottom: 20px;
}

.inputWithIcon .loginIcon {
  position: absolute;
  left: 19px;
  top: 12px;
  fill: #b9c9d4;
}

.button {
  display: block;
  margin: auto;
  margin-top: 30px;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  width: 275px;
  height: 39px;
  background-color: #fc842c;
  line-height: 30px;
  border: none;
  border-radius: 5px;
  margin-bottom: 27px;
  font-family: 'HelveticaNeueCyr';
}

.button:hover {
  box-shadow: 2px 3px 3px rgba(252, 132, 44, 0.25);
}

.link {
  color: #6b7d83;
  font-size: 12px;
  letter-spacing: 0.36px;
  text-decoration: underline;
  font-weight: 400;
  line-height: 30px;
  font-family: 'HelveticaNeueCyr';
}

.error {
  position: absolute;
  color: red;
  font-size: 14px;
}

.name {
  display: none;
  color: #fff;
  font-size: 24px;
  font-family: 'BauhausC';
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}

@media (min-width: 768px) {
  .backgroundLogin {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    background-image: url(../../images/backgroundLogin.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .container {
    width: 550px;
    min-height: 390px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    border-radius: 5px;
  }

  .logoContainer {
    min-width: 120px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 34px;
    height: 24px;
  }

  .title {
    padding-top: 5px;
  }

  .input {
    min-width: 435px;
    min-height: 57px;
  }

  .inputWithIcon .loginIcon {
    top: 16px;
  }

  .button {
    width: 282px;
  }

  .name {
    display: block;
  }
}

@media (min-width: 1280px) {
  .backgroundLogin {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 513px;
    min-height: 100%;
    background-image: url(../../images/backgroundLoginDesktop.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 513px 100%;
  }

  .container {
    right: 10%;
    top: 50%;
    transform: translate(0, -50%);
  }

  .name {
    position: absolute;
    bottom: 40px;
  }
}

.googleImage {
  width: 25px;
  height: 25px;
}
