.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 30px;
  z-index: 1;
}

.modal {
  width: 100%;
  height: 100%;
  background-color: var(--text-color-white);
}

@media (min-width: 768px) {
  .backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
  }

  .modal {
    width: 500px;
    margin: auto;
    height: auto;
    border-radius: 6px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.35);
  }
}
