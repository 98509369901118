.title {
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 30px;
  color: var(--text-color-white);
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'HelveticaNeueCyr';
}

.titleWrapper {
  background-color: var(--color-main-blue);
}

.controlWrapper {
  margin: 0 auto;
  width: 320px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
}

.closeModalButton {
  width: 44px;
  height: 100%;
  background-color: var(--color-main-blue);
  border: none;
  outline: none;
  color: var(--text-color-white);
}
.closeModalButton img {
  width: 44px;
  height: 14px;
  display: inherit;
}

.transactionForm {
  margin: 0 auto;
  width: 320px;
  padding: 20px;
  outline: none;
  color: var(--color-main-blue);
  font-family: 'HelveticaNeueCyr';
}

.typeOfTransactionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  margin-bottom: 30px;
}

.typeOfTransactionWrapper::after {
  width: 1px;
  height: 100%;
  left: 50%;
  background-color: #b9c9d4;
  transform: translateX(-50%);
  position: absolute;
  content: '';
  display: block;
  visibility: visible;
}

.typeRadio {
  position: relative;
  cursor: pointer;
}

.typeRadio + label {
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 18px;
  color: #b9c9d4;
  cursor: pointer;
}

.typeRadio:checked + label {
  color: var(--color-main-blue);
}

.typeRadio[value='expense']:checked + label {
  color: var(--color-main-orange);
}

.typeRadio:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: -50%;
  left: -50%;
  transform: translate(50%, 50%);
  position: absolute;
  background-color: white;
  content: '';
  display: block;
  visibility: visible;
  border: 2px solid var(--color-main-blue);
}

.typeRadio:checked:before {
  width: 40%;
  height: 40%;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: var(--color-main-blue);
  content: '';
  display: block;
  visibility: visible;
  border: none;
  z-index: 1;
}

.typeRadio[value='expense']:checked:before {
  background-color: var(--color-main-orange);
}

.dateAndValueWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
}

.dateAndValueWrapper input {
  width: 130px;
}

.select {
  margin-bottom: 30px;
}

.valueInput {
  width: 130px;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 30px;
  color: var(--color-main-blue);
  border: 2px solid #b9c9d4;
  border-radius: 4px;
  outline: none;
  font-weight: bold;
  text-align: right;
  padding-right: 26px;
}

.comment {
  width: 280px;
  margin: 0 auto;
}

.inputComment {
  min-height: 54px;
  resize: none;
  width: 100%;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 18px;
  color: var(--color-main-blue);
  border: 2px solid #b9c9d4;
  border-radius: 4px;
  outline: none;
  padding: 8px;
  overflow-y: visible;
}

.valueInput::placeholder,
.inputComment::placeholder {
  color: var(--color-main-blue);
  line-height: 18px;
}

.comment p {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 30px;
  color: var(--color-main-blue);
  font-weight: bold;
}

.transactionButton {
  border-radius: 4px;
  background-color: var(--color-main-orange);
  border: none;
  color: var(--text-color-white);
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  display: block;
  width: 280px;
  min-height: 40px;
  position: fixed;
  bottom: 30px;
}

input:focus,
textarea:focus {
  border-color: var(--bg-color-dark-blue);
}

.errorsContainer {
  margin-bottom: 30px;
}

.error {
  color: red;
}

@media (min-width: 768px) {
  .transactionButton {
    position: static;
    background-color: var(--color-main-blue);
  }

  .inputComment {
    margin-bottom: 30px;
  }

  .titleWrapper {
    background-color: #f3f6f6;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .title {
    color: var(--text-color-main);
    font-size: 15px;
    text-align: center;
    padding: 20px 0;
  }
  .controlWrapper {
    justify-content: center;
  }
}
