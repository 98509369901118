.meter {
  height: 5px;
  position: relative;
  background: #b9c9d4;
  top: 15px;
  border-radius: 50px;
}

.meter .indicator {
  display: block;
  height: 5px;
  border-radius: 50px;
  position: relative;
}

.red .indicator {
  background-color: #f0a3a3;
  background-image: linear-gradient(to bottom, #f0a3a3, #f42323);
  width: 25%;
}

.orange .indicator {
  background-color: #f1a165;
  background-image: linear-gradient(to bottom, #f1a165, #f36d0a);
  width: 50%;
}

.green .indicator {
  width: 100%;
  background-color: rgb(43, 194, 83);
  background-image: linear-gradient(
    center bottom,
    rgb(43, 194, 83) 37%,
    rgb(84, 240, 84) 69%
  );
}
