.currencyTable {
  width: 100%;
  height: 100%;
  margin: auto;
  background-image: url(../../assets/images/bgImg.png);
  background-position: 60% bottom;
  background-repeat: no-repeat;
}

.currencyHeadline {
  display: flex;
  height: 62px;
  background-color: #f3f6f6;
  margin: auto;
  vertical-align: inherit;
}

.currencyBoard {
  display: flex;
  height: 40px;
  border-bottom: 2px solid #f3f6f6;
  margin: auto;
  vertical-align: inherit;
}

.headline,
.mainLine {
  width: 33%;
  text-align: center;
  vertical-align: inherit;
}

.loader {
  padding-top: 40%;
  text-align: center;
}

.error {
  padding: 20px;
  background-color: #f44336;
  color: white;
  margin-bottom: 15px;
}

.currencyHeadline {
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  line-height: center;
  vertical-align: inherit;
}

.currencyBoard {
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
}

.mainLine {
  vertical-align: inherit;
  padding: 13px;
}

.headline {
  padding: 24px 0;
  text-align: center;
}

.currencyTable {
  margin: auto;
}

@media screen and (min-width: 768px) {
  .currencyTable,
  .currencyBoard,
  .currencyHeadline {
    width: 100%;
  }

  .currencyTable {
    background-image: none;
  }
}

@media (min-width: 1280px) {
  .currencyTable {
    width: 100%;
    /* height: 400px; */
    background-image: url(../../assets/images/bgImg.png);
    background-position: 58% 100%;
    background-repeat: no-repeat;
    padding-bottom: 220px;
  }
}
