.table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  text-align: left;
}

.th {
  padding: 20px 10px;
}

.table:nth-child(odd) tr {
  border-left: 8px solid var(--color-main-blue);
}

.table:nth-child(even) tr {
  border-left: 8px solid var(--color-main-orange);
}

.tr:nth-child(odd) {
  background: #fff;
}

@media (min-width: 768px) {
  .table {
    text-align: center;
  }

  .table:nth-child(odd) tr {
    border-left: none;
  }

  .table:nth-child(even) tr {
    border-left: none;
  }

  .th {
    padding: 30px 0;
    background: #fff;
  }

  .th:first-child {
    padding-left: 10px;
  }

  .th:last-child {
    padding-right: 10px;
  }

  .tr:nth-child(even) {
    background: #f3f6f6;
  }

  .tr:nth-child(odd) {
    background: none;
  }

  .td {
    padding: 30px 0;

    text-align: center;
  }
}

.transactionHistory {
  font-family: 'HelveticaNeueCyr', sans-serif;
}
.addTransaction {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}
.transactionHead {
  display: none;
}
.transaction {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 0 15px;
}
.pair {
  width: 100%;
  display: flex;
  line-height: 62px;
}
.pair:nth-child(odd) {
  background: #f4f5f7;
}
/* .key {
  flex-basis: 100%;
  padding: 0 0 0 15px;
} */
.val {
  flex-basis: 100%;
  white-space: nowrap;
}
.hilite {
  color: var(--color-main-orange);
}
.transaction:nth-child(odd) .key {
  border-left: 8px solid var(--color-main-orange);
}
.transaction:nth-child(even) .key {
  border-left: 8px solid #777;
}
.deleteBtn {
  height: 20px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .transactionHead {
    display: flex;
    justify-content: space-between;
  }
  .transactionHead div {
    padding: 0 10px;
    font-weight: bold;
    line-height: 72px;
    text-transform: uppercase;
    width: 100%;
    background: #fff;
  }
  .transaction {
    flex-direction: row;
    margin: 0;
  }
  .pair:nth-child(odd) {
    background: none;
  }
  .transaction:nth-child(odd) .pair {
    background: #f3f6f6;
  }
  .transaction:nth-child(even) .pair {
    background: transparent;
  }
  .key {
    display: none;
  }
  .val {
    padding: 0 10px;
    max-width: 100px;
  }
  .textCenter {
    text-align: center;
  }
}

@media (min-width: 1280px) {
  .transactionHistory {
    max-height: 60vh;
    overflow-y: auto;
  }
  /* width */
  .transactionHistory::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  .transactionHistory::-webkit-scrollbar-track {
    background: #c1cdd2;
  }
  /* Handle */
  .transactionHistory::-webkit-scrollbar-thumb {
    background: #657792;
    border-radius: 6px;
  }
  /* Handle on hover */
  .transactionHistory::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .addTransaction {
    width: 400px;
    margin-top: 100px;
  }
}
